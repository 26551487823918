import { useEffect, useState } from 'react';
import { TypedPatch, MFEBootstrap } from '@mfe/state-management';
import { Observable } from '@mfe/state-management-interfaces';
import { BulkEditPageState } from 'src/model/BulkEditPageState';

type StatePatchApplier = (
  patches: TypedPatch.TypedPatch<BulkEditPageState>[]
) => void;

/**
 * Custom React hook to expose bulk edit page state for both reads and writes.
 * @param pageState MFE page state that stores lbulk edit page data
 * @param subscribeToUpdates Flag on whether to subscribe globalState to updates made to
 * the page state
 * @returns An array of size 2 with the object types at the following array indices:
 * 0: BulkEditPageState - a read-only object that can optionally be subscribed
 * to updates to the page state
 * 1: StatePatchApplier - a function that updates the page state via patches
 */
function useBulkEditPageState(
  pageState: MFEBootstrap.PageState<BulkEditPageState>,
  subscribeToUpdates?: boolean
): [BulkEditPageState, StatePatchApplier] {
  const [globalState, setGlobalState] = useState(
    pageState.getCurrentPageState()
  );

  useEffect(() => {
    let unsubscribable: Observable.Unsubscribable;

    if (subscribeToUpdates)
      unsubscribable = pageState.readablePageState.subscribe({
        next: (state) => {
          setGlobalState({ ...state });
        },
        error: (e) => {
          console.error(e);
        },
      });

    return () => {
      if (unsubscribable) unsubscribable.unsubscribe();
    };
  }, []);

  const applyStatePatches = (
    patches: TypedPatch.TypedPatch<BulkEditPageState>[]
  ): void => {
    pageState.writeablePageState.next(patches);
  };

  return [globalState, applyStatePatches];
}

export default useBulkEditPageState;
