import { LineItemElement } from '@amzn/d16g-rodeo-card-common';
import React from 'react';
import { ComponentNames } from 'src/utils/MFEConstants';
import { get } from 'lodash-es';
import ApiData from 'src/MFE/lineItem/model/api';
import { CUSTOM_ATTRIBUTES } from 'src/utils/ApiData';
import BulkEditAudienceTargetingContainer from 'src/MFE/bulkEdit/BulkEditAudienceTargetingContainer';
import MockPageStateBuilder from 'src/mock/MockPageStateBuilder';
import { mockStandardDisplayBulkEditModel } from 'src/mock/MockStandardDisplayBulkEditState';
import mockedCustomAttributes from 'src/MFE/lineItem/mock/MockCustomElementAttributes.json';
import { BulkEditPageState } from 'src/model/BulkEditPageState';
import { DATA_MFE_CUSTOM_ATTRIBUTE_NAME } from '@mfe/state-management';
import BulkEditShadowDOMWrapper from 'src/MFE/bulkEdit/BulkEditShadowDomWrapper';
import { getKatalLogger } from 'src/utils/KatalLogger';
import {
  SegmentInsights,
  TaktLoggingContextProvider,
} from '@amzn/d16g-audience-picker-component';
import LocalizationWrapper from '../../Localization/LocalizationWrapper';
import { BulkEditContextWrapper } from '@amzn/d16g-pricing-react-utils';

class BulkEditAudienceTargetingMFE extends LineItemElement<BulkEditPageState> {
  connectedCallback() {
    super.connectedCallback();

    if (USE_MOCKS) {
      const PageStateBuilder = new MockPageStateBuilder();
      this.mfe = {
        pageState: PageStateBuilder.build(mockStandardDisplayBulkEditModel),
      };
      this.setAttribute(
        'customElementAttributes',
        JSON.stringify(mockedCustomAttributes)
      );
      this.dispatchEvent(new window.Event('MFEFrameworkInitialized'));
    }
  }
  // @ts-ignore
  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (oldValue !== newValue) this.renderMFE();
  }

  renderMFE() {
    try {
      const that = this;
      const customElementAttributes = JSON.parse(
        this.getAttribute('customElementAttributes')
      );

      const dataMFECustomAttribute = JSON.parse(
        this.getAttribute(DATA_MFE_CUSTOM_ATTRIBUTE_NAME)
      );

      const getCustomElementAttribute = (elementName) => {
        if (USE_MOCKS) return get(customElementAttributes, elementName);

        return that.getAttribute(elementName);
      };

      const apiData: ApiData = {
        advertiserId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.advertiserId),
        orderId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.campaignId),
        entityId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.entityId),
        lineItemId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.lineItemId),
        marketplaceId: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.marketplaceId
        ),
        clientId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.clientId),
        country: getCustomElementAttribute(CUSTOM_ATTRIBUTES.country),
        csrfToken: getCustomElementAttribute(CUSTOM_ATTRIBUTES.csrfToken),
        defaultFilters: [
          {
            field: 'status',
            values: ['Active'],
          },
        ],
        baseURL: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.audienceDiscoveryUrl
        ),
        baseUrl: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.audienceDiscoveryUrl
        ),
        endpoint: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.audienceDiscoveryEndpoint
        ),
        currencyOfPreference: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.currencyOfPreference
        ),
        fxConversionRate: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.fxConversionRate
        ),
        personaBuilderUrl: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.personaBuilderUrl
        ),
        personaBuilderTopOverlappingEndpoint: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.personaBuilderTopOverlappingEndpoint
        ),
      };

      const taktLoggingInitialContext = {
        entityId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.entityId),
        lineItemId: undefined,
        advertiserId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.advertiserId),
        campaignId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.campaignId),
        suggestedSegments: [] as SegmentInsights[],
      };

      return (
        <TaktLoggingContextProvider initialState={taktLoggingInitialContext}>
          <BulkEditShadowDOMWrapper>
            <BulkEditContextWrapper
              apiData={apiData}
              pageState={this.mfe?.pageState}
              initStoreProps={{
                currencyOfPreference: apiData.currencyOfPreference as any,
                fxConversionRate: apiData.fxConversionRate as any,
                currencyOfMarketplace: apiData.currencyOfPreference as any,
              }}
            >
              <LocalizationWrapper pageState={this.mfe.pageState}>
                <BulkEditAudienceTargetingContainer
                  adTypes={dataMFECustomAttribute}
                  pageState={this.mfe?.pageState}
                  apiData={apiData}
                  isEntitySuggestionAllowListed={
                    getCustomElementAttribute(
                      CUSTOM_ATTRIBUTES.isEntitySuggestionsAllowListed
                    ) === 'true'
                  }
                  isInclusionDisabled={
                    getCustomElementAttribute(
                      CUSTOM_ATTRIBUTES.isInclusionDisabled
                    ) === 'true'
                  }
                  inclusionDisabledReasonCode={getCustomElementAttribute(
                    CUSTOM_ATTRIBUTES.inclusionDisabledTooltip
                  )}
                />
              </LocalizationWrapper>
            </BulkEditContextWrapper>
          </BulkEditShadowDOMWrapper>
        </TaktLoggingContextProvider>
      );
    } catch (e: any) {
      const logger = getKatalLogger();
      logger.error(
        'Bulk edit Audience Picker MFE failed to render',
        e,
        this.mfe.pageState.getCurrentPageState()
      );
    }
  }
}

customElements.define(
  ComponentNames.BULK_EDIT_TARGETING,
  BulkEditAudienceTargetingMFE
);

export default BulkEditAudienceTargetingMFE;
