import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { Divider, Dropdown, DropdownItem, Text } from '@amzn/storm-ui';
import { MFEBootstrap } from '@mfe/state-management';
import ApiData from 'src/MFE/lineItem/model/api';
import '@amzn/unified-data-table-components/styles/css/udc-theme.css';
import {
  AudiencePicker,
  TaktLoggingContext,
  logAudienceSuggestionsEvent,
  TaktLoggingContextType,
  SegmentInsights,
} from '@amzn/d16g-audience-picker-component';
import { ComponentIds } from 'src/utils/MFEConstants';
import styled from 'styled-components';
/* eslint-disable */
// @ts-ignore eslint-disable
import udcTheme from '!!to-string-loader!css-loader!@amzn/unified-data-table-components/styles/css/udc-theme.css';
import { useBulkEditFormField } from 'src/utils/hooks/useFormField';
import {
  MutationType,
  SegmentSet,
} from '@amzn/d16g-state-management-interfaces/dist/v1/BulkEdit';
import { Segment } from '@amzn/d16g-state-management-interfaces/src/v1/LineItem';
import { BulkEditV1 } from '@amzn/d16g-state-management-interfaces';
import { BulkEditPageState } from 'src/model/BulkEditPageState';
import { AudienceTargeting } from '@amzn/d16g-audience-picker-component';
import {
  ConvertFromLegacySegmentStructure,
  ConvertFromMutateSegmentList,
  ConvertToLegacySegmentStructure,
  ConvertToMutateSegmentList,
  LegacyAudienceGroup,
  UpdateSegmentsByMutationType,
  EMPTY_LEGACY_AUDIENCE_TARGETING,
} from 'src/utils/AudienceTargetingConverters';
import {
  AdTypes,
  ConvertAdTypeToFeeType,
} from 'src/utils/ImpressionSupplyType';
import { disable3PForKindle } from 'src/utils/disableCallbacks';
import { useBundle, useLocalizationContext } from '@amzn/react-arb-tools';
import { translateText } from 'src/Localization/LocalizationText';
import { TranslatorString } from 'src/Localization/LocalizationConfig';
import { getLocaleFromState } from 'src/Localization/LocalizationWrapper';
import { mockAudienceTargetingGroup } from 'src/MFE/bulkEdit/mock/MockAudienceTargetingGroups';
/* eslint-enable */

interface BulkEditAudienceTargetingContainerProps {
  pageState: MFEBootstrap.PageState<BulkEditPageState>;
  apiData: ApiData;
  adTypes?: string[];
  isEntitySuggestionAllowListed?: boolean;
  isInclusionDisabled?: boolean;
  inclusionDisabledReasonCode?: string;
}

const BulkEditAudiencePickerContainer = styled.div`
  min-width: 900px;
  width: 65vw;
`;

const BulkEditDescriptionContainer = styled(Text)`
  margin-top: 10px;
`;

const DescriptionDivider = styled(Divider)`
  margin-bottom: 0px;
`;

function BulkEditAudienceTargetingContainer({
  pageState,
  apiData,
  adTypes,
  isEntitySuggestionAllowListed,
  isInclusionDisabled,
  inclusionDisabledReasonCode,
}: BulkEditAudienceTargetingContainerProps) {
  const { entityId, lineItemId, advertiserId, campaignId, suggestedSegments } =
    useContext<TaktLoggingContextType>(TaktLoggingContext);

  const suggestedSegmentsRef = useRef<SegmentInsights[]>([]);
  useEffect(() => {
    suggestedSegmentsRef.current = suggestedSegments;
  }, [suggestedSegments]);

  const [bundle, isBundleLoading] = useBundle('strings');

  // Log these events on component unmount, as we are unable to track the save event.
  // eslint-disable-next-line no-warning-comments
  // TODO: Move this to the lineItem/MFE save event once it is figured out.
  useEffect(
    () => () => {
      logAudienceSuggestionsEvent({
        advertiserId,
        entityId,
        lineItemId,
        campaignId,
        suggestedSegments: suggestedSegmentsRef.current,
      });
    },
    []
  );

  const [audienceTargetingUpdateState] =
    useBulkEditFormField<BulkEditV1.AudienceTargetingSettingUpdateState>(
      (bulkEditModel) => bulkEditModel?.audienceTargetingUpdateState,
      ComponentIds.BULK_EDIT_TARGETING,
      pageState,
      {
        mutationType: MutationType.REPLACE,
        segmentTargetingDetails: { mutateSegments: [], segmentSets: [] },
        targetingFeature:
          BulkEditV1.BulkEditTargetingFeature.AUDIENCE_TARGETING,
      }
    );

  const [mutationType, setMutationType] = useBulkEditFormField<MutationType>(
    (bulkEditModel) =>
      bulkEditModel?.audienceTargetingUpdateState?.mutationType,
    ComponentIds.BULK_EDIT_TARGETING,
    pageState,
    MutationType.REPLACE
  );

  const [mutateSegments, setMutateSegments] = useBulkEditFormField<Segment[]>(
    (bulkEditModel) =>
      bulkEditModel?.audienceTargetingUpdateState?.segmentTargetingDetails
        ?.mutateSegments,
    ComponentIds.BULK_EDIT_TARGETING,
    pageState,
    []
  );

  const [segmentSets, setSegmentSets] = useBulkEditFormField<
    BulkEditV1.SegmentSet[]
  >(
    (bulkEditModel) =>
      bulkEditModel?.audienceTargetingUpdateState?.segmentTargetingDetails
        ?.segmentSets,
    ComponentIds.BULK_EDIT_TARGETING,
    pageState,
    [EMPTY_LEGACY_AUDIENCE_TARGETING as SegmentSet]
  );

  const onAudienceChange = useCallback(
    (audiences: AudienceTargeting) => {
      switch (mutationType?.value) {
        case MutationType.INCLUDE:
        case MutationType.EXCLUDE:
        case MutationType.REMOVE:
          setMutateSegments(
            ConvertToMutateSegmentList(audiences, mutationType.value)
          );
          break;
        case MutationType.REPLACE:
          setSegmentSets(ConvertToLegacySegmentStructure(audiences));
          break;
        default:
          console.error('Invalid mutation type was selected.');
      }
    },
    [mutationType.value]
  );

  const DropdownText = (dropdownItem, value) => {
    if (value) return dropdownItem;
    return translateText(
      bundle,
      TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_EMPTY_DROPDOWN_TEXT
    );
  };

  const getInitialState = () => {
    if (USE_MOCKS)
      return ConvertFromLegacySegmentStructure(mockAudienceTargetingGroup);
    else if (mutationType?.value === MutationType.REPLACE)
      return ConvertFromLegacySegmentStructure(
        segmentSets.value as LegacyAudienceGroup[]
      );

    return ConvertFromMutateSegmentList(mutateSegments.value);
  };

  const BulkEditDropdownItems = {
    INCLUDE: {
      label: translateText(
        bundle,
        TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_INCLUDE_LABEL
      ),
      description: translateText(
        bundle,
        TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_INCLUDE_DESC
      ),
      stringId: 'include-audiences',
    },
    EXCLUDE: {
      label: translateText(
        bundle,
        TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_EXCLUDE_LABEL
      ),
      description: translateText(
        bundle,
        TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_EXCLUDE_DESC
      ),
      stringId: 'exclude-audiences',
    },
    REPLACE: {
      label: translateText(
        bundle,
        TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_REPLACE_LABEL
      ),
      description: '',
      stringId: 'replace-audiences',
    },
    REMOVE: {
      label: translateText(
        bundle,
        TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_REMOVE_LABEL
      ),
      description: translateText(
        bundle,
        TranslatorString.ADSP_D16G_AUDIENCE_PICKER_MFE_WEBSITE_BULK_EDIT_OPTIONS_REMOVE_DESC
      ),
      stringId: 'remove-audiences',
    },
  };
  const getStringId = () =>
    mutationType?.value !== MutationType.REPLACE
      ? BulkEditDropdownItems[mutationType?.value].stringId
      : '';

  const onMutationChange = (mutation: MutationType) => {
    setMutateSegments(
      UpdateSegmentsByMutationType(mutateSegments.value, mutation)
    );
    setMutationType(mutation);
  };

  const showSuggestions =
    isEntitySuggestionAllowListed &&
    (mutationType.value === MutationType.INCLUDE ||
      mutationType.value === MutationType.REPLACE);

  if (!mutationType?.value || !audienceTargetingUpdateState.value) return null;
  if (isBundleLoading) return null;
  return (
    <BulkEditAudiencePickerContainer>
      <style type="text/css">{udcTheme}</style>
      <Dropdown
        onChange={onMutationChange}
        selectedValue={mutationType?.value}
        onOverrideLabel={DropdownText}
      >
        {Object.keys(BulkEditDropdownItems).map((item) => (
          <DropdownItem value={item} key={item}>
            {BulkEditDropdownItems[item].label}
          </DropdownItem>
        ))}
      </Dropdown>
      {BulkEditDropdownItems[mutationType?.value]?.description && (
        <BulkEditDescriptionContainer>
          {BulkEditDropdownItems[mutationType?.value]?.description}
        </BulkEditDescriptionContainer>
      )}
      <DescriptionDivider />
      {mutationType?.value && (
        <AudiencePicker
          key={mutationType?.value}
          onChange={onAudienceChange}
          apiData={apiData}
          useCategoryDropdownFilter={true}
          feeSupplyType={ConvertAdTypeToFeeType(adTypes)}
          initialAudienceState={getInitialState()}
          bulkEditMutationType={getStringId()}
          useLegacy={mutationType?.value === MutationType.REPLACE}
          // @ts-ignore
          disableRowsCallback={(data) =>
            adTypes.includes(AdTypes.KINDLE) && disable3PForKindle(data)
              ? translateText(bundle, disable3PForKindle(data))
              : undefined
          }
          maxSegmentsPerGroup={500}
          maxIncludeGroups={25}
          showSuggestions={showSuggestions}
          isInclusionDisabled={isInclusionDisabled}
          inclusionDisabledReasonCode={inclusionDisabledReasonCode}
          locale={getLocaleFromState(pageState)}
        />
      )}
    </BulkEditAudiencePickerContainer>
  );
}

export default BulkEditAudienceTargetingContainer;
